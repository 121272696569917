import React from 'react'
import styled from '@emotion/styled'

const IMG = styled.img`
  width: 100vw;
  max-height: 345px;
  min-height: 345px;
  position: absolute;
  object-fit: cover;
  left: 0;
  z-index: -1;
  /* top: 50%; */
  transform: translateY(-10%);
  z-index: 1;
`
const BGImage = ({ src, alt }) => <IMG src={src} alt={alt} />

export default BGImage
