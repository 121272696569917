const colors = {
  black: '#24292E',
  white: '#fff',
  gray: '#fafafa',
  red: '#E74C3C',
  badass: '#C9FA4B',
  green: '#29B573',
  yellow: '#D8DE26',
}

export default colors
