import React from 'react'
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '../Theming'
import { faMoon, faSun } from '@fortawesome/free-regular-svg-icons'

const ThemeToggler = ({ toggleTheme, themeName }) => {
  const theme = useTheme()
  return (
    <Button
      css={{
        borderRadius: '50%',
        width: '2.6rem',
        height: '2.6rem',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        borderStyle: 'solid',
        borderWidth: '1px',
        color: theme.colors.text,
        background: themeName === 'dark' ? theme.colors.headerBg : theme.colors.text,

        '@media (hover: hover)': {
          ':hover': {
            background:
              theme.themeName === 'default'
                ? theme.colors.text
                : theme.colors.primary,
          },
        },
      }}
      aria-label={
        themeName === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'
      }
      onClick={() => toggleTheme(themeName === 'dark' ? 'default' : 'dark')}
    >
      {themeName === 'dark' ? (
        <FontAwesomeIcon icon={faSun} size="lg" color="LemonChiffon" />
      ) : (
        <FontAwesomeIcon icon={faMoon} size="lg" color="LemonChiffon" />
      )}
    </Button>
  )
}
export default ThemeToggler
