import React from 'react'
import { useTheme } from 'components/Theming'
import { faLeaf, faSeedling, faTree, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Tags = ({ children, garden }) => {
  const theme = useTheme();
  return (
    children && (
      <ul style={{ marginBottom: 0, marginLeft: 0, display: 'inline-flex' }}>
        {children.split(', ').map(tag => (
          <li
            key={tag}
            style={{
              borderRadius: `4px`,
              border: `1px solid ${theme.colors.link}`,
              padding: `2px 6px`,
              marginRight: `5px`,
              fontSize: `80%`,
              backgroundColor: garden ? theme.colors.green : theme.colors.quote,
              color: theme.colors.headerBg,
              listStyle: 'none',
            }}
          >
            {garden && <FontAwesomeIcon
              pull='left'
              icon={
                garden === 'Evergreen'
                  ? faTree
                  : garden === 'Seeding'
                    ? faSeedling
                    : garden === 'Budding'
                      ? faLeaf
                      : faQuestion
              }
              size="lg"
              color={theme.colors.headerBg}
            />}
            {tag}
          </li>
        ))}
      </ul>
    )
  )
}

export default Tags
