import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import BGImage from 'components/Image'
import { useTheme } from 'components/Theming'
import Container from 'components/Container'
import { rhythm } from '../lib/typography'
import alan from '../../static/images/alan.jpg'
import src from '../../static/images/bg.jpg'
import blogSrc from '../../static/images/mid.jpg'
import Tags from '../components/Tags'

const Description = styled.p`
  margin-bottom: 10px;
  display: inline-block;
  line-height: 2;
`

const Hero = props => {
  const theme = useTheme()
  return (
    <section
      css={css`
        color: ${props.color === 'dark'
          ? theme.colors.black
          : theme.colors.white};
        width: 100%;
        padding: 20px 0 30px 0;
        display: flex;
        background: ${theme.colors.black};
      `}
    >
      {!props.post ? (
        <BGImage alt="background" src={props.blog ? blogSrc : src} />
      ) : (
        <BGImage
          alt="background"
          src={require(`../../static/images/${props.post}`)}
        />
      )}

      <Container
        css={css`
          display: flex;
          flex-direction: column;
          background: ${props.blog
            ? 'rgba(36, 41, 46, 0.4);'
            : 'rgba(36, 41, 46, 0.0);'};
          z-index: 1;
        `}
      >
        <h1
          css={css`
            color: ${props.color === 'dark'
              ? theme.colors.black
              : theme.colors.white};
            position: relative;
            z-index: 5;
            line-height: 1.5;
            margin: 0;
            max-width: ${rhythm(15)};
          `}
        >
          {props.avatar && (
            <img
              src={alan}
              alt="alan with a bike"
              css={css`
                object-fit: cover;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                margin-right: 1rem;
                position: relative;
              `}
            />
          )}
          {props.heading}
        </h1>

        <Description
          css={css`
            z-index: 5;
          `}
        >
          {props.subHeading}
          <br></br>
          {props.subHeading2}
          <br></br>
          {props.tags && props.garden && (
            <>
              <Tags garden={false}>{props.tags}</Tags>
              <Tags garden={props.garden}>{props.garden}</Tags>
            </>
          )}
        </Description>
      </Container>
      <div
        css={css`
          height: 150px;
          overflow: hidden;
        `}
      />
    </section>
  )
}

export default Hero
